import { GridValueGetterParams } from "@material-ui/data-grid"
import type { Validator } from "../components/Form/FormRows"
import { ErrorResponse } from "./commonTypes"
import { alert } from "./dialogs/alert"

export type RequestOptions = Omit<RequestInit, 'method'>

// ビルド時環境変数からエンドポイント粗取得
export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT

export const post = (path: string, init: RequestOptions) => {
  return fetch(apiEndpoint + path, {...init, method: 'post'})
}
export const get = (path: string, init: RequestOptions) => fetch(apiEndpoint + path, {...init, method: 'get'})

/**
 * AWSSDK側で最終ログインユーザー取得がないため
 * @returns 最終ログインのユーザー名
 */
export const getLastAuthUser = () => Object.entries(localStorage).find(([k,v]) => k.startsWith('CognitoIdentityServiceProvider') && k.endsWith('LastAuthUser'))?.[1]

/**
 * キーをケバブケースからキャメルケースに変換するパーサー
 * @param json JSON文字列
 * @returns パースしたJSON
 */
export const camelizedParser = <T = any>(json: string): T =>  JSON.parse(json, (_, v) =>
  v instanceof Object && !(v instanceof Array) ? Object.entries(v).map(([k, o]) => [k.split('-').map((s, i) => i > 0 ? s.charAt(0).toUpperCase() + s.slice(1) : s).join(''), o as any]).reduce((o,[k,v]) => { o[k] = v; return o }, {} as any) : v
)

/**
 * キーをキャメルケースからケバブケースに変換するエンコーダー
 * @param obj オブジェクト
 * @returns JSON文字列
 */
export const kebabizedStringifier = (obj: Object) => JSON.stringify(obj, (key, v) => 
  v instanceof Object && !(v instanceof Array) ? Object.entries(v).reduce((p, [k, o]) => {p[k.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()] = o; return p}, {} as any) : v
)
/** キャメルケースをケバブケースに変換 */
export const kebabize = (text: string) => text.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()

/** 2層のみ値コピーを行う */
export function twoLayerCopy<T extends Object>(obj: T): T {
  if (obj instanceof Array) {
    return Object.entries(obj).reduce((p, [k, v]) => {
      if('map' in v) {
        p[k] = [...v]
      } else if (v instanceof Object) {
        p[k] = {...v}
      } else {
        p[k] = v
      }
      return p
    }, [] as any)
  } else {
    return Object.entries(obj).reduce((p, [k, v]) => {
      if('map' in v) {
        p[k] = [...v]
      } else if (v instanceof Object) {
        p[k] = {...v}
      } else {
        p[k] = v
      }
      return p
    }, {} as any)
  }
}

/**
 * 時刻文字列を0埋めして再フォーマット
 * @param str 時刻文字列(h:M:S)
 * @returns 再フォーマットした文字列
 */
export const reformatTime = (str: string) => str.split(':').map(c => c.padStart(2, '0')).join(':')
/**
 * @param date 日時オブジェクト
 * @returns 日付文字列 (yyyy/mm/dd)
 */
export const formatDateStr = (date: Date) => `${String(date.getFullYear())}/${String(date.getMonth()+1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`
/**
 * @param time 日時オブジェクト
 * @returns 時刻文字列 (hh:MM:SS)
 */
export const formatTimeStr = (time: Date) => `${String(time.getHours()).padStart(2, '0')}:${String(time.getMinutes()).padStart(2, '0')}:${String(time.getSeconds()).padStart(2, '0')}`
/**
 * @param datetime 日時オブジェクト
 * @returns 日時文字列 (yyyy/mm/dd hh:MM:SS)
 */
export const formatDateTimeStr = (datetime: Date) => formatDateStr(datetime) + ' ' + formatTimeStr(datetime)
/**
 * DataGridのセルに日時を表示するため
 */
export const cellTimeGetter = ({value}: GridValueGetterParams) => formatDateTimeStr(new Date(value as string))
/**
 * 時刻のみの文字列から日時オブジェクトを生成します
 * @param str 時刻文字列
 * @returns 日時オブジェクト(日付は今日)
 */
export const parseTimeStr = (str: string|undefined) => {
  if(!str) return undefined
  const time = new Date()
  if(!/([01]?[0-9]|2[0-3])(:[0-5]?[0-9]){2}/.test(str)) return
  const [hours, minutes, seconds] = str.split(':')
  time.setHours(+hours)
  time.setMinutes(+minutes)
  time.setSeconds(+seconds)
  return time
}

/**
 * @param text クリップボードにコピーする文字列
 */
export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text)

/**
 * HTTPのエラーをいい感じに表示
 * @param res HTTPレスポンス
 * @param title エラータイトル
 * @returns 正常か否か
 */
export const ifErrResponsePopper = async (res: Response, title: string): Promise<boolean> => {
  if (!res.ok) {
    const errorRes = await res.json() as ErrorResponse
    alert(title, errorRes.error.message)
    return false
  }
  return true
}

export const pathValidator: Validator = {
  re: /^[a-zA-Z0-9\-_]+$/,
  helperText: '半角英数字と"-_"のみ利用できます',
}

export const max50Validator: Validator = {
  re: /^.{0,50}$/,
  helperText: '50文字以下である必要があります',
}

export const max100Valicator: Validator = {
  re: /^.{0,100}$/,
  helperText: '100文字以下である必要があります'
}

export const max255Validator: Validator = {
  re: /^.{0,255}$/,
  helperText: '255文字以下である必要があります',
}
