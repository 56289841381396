import React from 'react'
import type { GridColDef } from '@material-ui/data-grid'
import TableComponent from '../components/TableComponent'
import {
  createMissionAsync,
  deleteMissionAsync,
  selectMissions,
  selectMissionsStatus,
  updateMissionAsync,
} from '../features/missions/missionsSlice'
import { selectTenantsForSelect } from '../features/tenants/tenantsSlice'
import { selectFloors } from '../features/floors/floorsSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectRobots } from '../features/robots/robotsSlice'
import { CreateMissionRequest } from '../features/missions/missionsTypes'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { confirm } from '../features/dialogs/confirm'
import { copyToClipboard, cellTimeGetter, pathValidator, max50Validator, max255Validator, max100Valicator } from '../features/utils'
import { FormValue } from '../components/Form/FormRows'

// const useStyles = makeStyles((theme) =>
//   createStyles({
//   }),
// )

const MissionPage: React.FC = () => {
  // const classes = useStyles()
  const missions = useAppSelector(selectMissions)
  const missionsStatus = useAppSelector(selectMissionsStatus)
  const floors = useAppSelector(selectFloors)
  const tenants = useAppSelector(selectTenantsForSelect)
  const robots = useAppSelector(selectRobots)
  const dispatch = useAppDispatch()
  let selectedRobotId: string
  let selectedRobotDriveUnit: string
  
  const isDriveUnitKeigan =(values: FormValue) => {
    const robotId = values.robotId as string
    if (selectedRobotId !== robotId) {
      selectedRobotId = robotId
      selectedRobotDriveUnit = ''
      const jsonData = robots.find(t => t.id === selectedRobotId)?.robotCompositions?.jsonData
      if (jsonData != null) {
        selectedRobotDriveUnit = JSON.parse(jsonData)?.common?.drive_unit
      }
    }
    return selectedRobotDriveUnit === 'kg'
  }
  
  const cols: GridColDef[] = [
    { field: 'id', headerName: 'ミッションセットID', width: 220 },
    { field: 'name', headerName: 'ミッションセット名', width: 220 },
    { field: 'kgTaskSetId', headerName: 'タスクセットID', width: 180 },
    {
      field: 'tenantId',
      headerName: 'テナントID',
      width: 200,
      // valueGetter({value}) {
      //   return (
      //     tenants.find((t) => t.value === value)?.name ?? value
      //     )
      //   },
    },
    {
      field: 'robotId',
      headerName: 'ロボット名',
      width: 200,
      valueGetter({ value }) {
        return robots.find((r) => r.id === value)?.name ?? value
      },
    },
    {
      field: 'floorId',
      headerName: 'フロア名',
      width: 150,
      valueGetter({ value }) {
        return floors.find((f) => f.id === value)?.name ?? value
      },
    },
    {
      field: 'playRule',
      headerName: '音声再生ルール',
      sortable: false,
      disableColumnMenu: true,
      width: 140,
      headerAlign: 'center',
      align: 'center',
      renderCell({ value }) {
        return (
          <Button
            onClick={() => {
              copyToClipboard(value as string)
            }}
            title="音声再生ルールのコピー"
          >
            <FileCopyIcon />
          </Button>
        )
      },
    },
    {
      field: 'nsMissionName',
      headerName: '実行するミッション名',
      minWidth: 220,
      // hide: true,
    },
    {
      field: 'nsStartPoseName',
      headerName: '開始位置名',
      minWidth: 150,
      // hide: true,
    },
    {
      field: 'nsMapName',
      headerName: '地図名',
      minWidth: 130,
      // hide: true,
    },
    { field: 'rhMapId', headerName: 'ROBO-HI用地図ID', width: 250 },
    { field: 'rhFloorCode', headerName: 'ROBO-HI用フロアコード', width: 250 },
    { field: 'remarks', headerName: '備考', width: 300 },
    {
      field: 'createAt',
      headerName: '作成日時',
      minWidth: 160,
      valueGetter: cellTimeGetter,
    },
    {
      field: 'updatedAt',
      headerName: '更新日時',
      minWidth: 160,
      hide: true,
      valueGetter: cellTimeGetter,
    },
  ]

  return (
    <>
      <TableComponent
        loading={missionsStatus === 'loading'}
        createForm={{
          formTitle: 'ミッションセット作成',
          FormRows: [
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants,
            },
            {
              type: 'select',
              name: 'robotId',
              label: 'ロボット名',
              items: robots,
              itemGetter(item) {
                return {
                  name: item.name,
                  value: item.id,
                }
              },
              filter(values, items) {
                return items.filter((i) => i.tenantId === values['tenantId'])
              },
            },
            {
              name: 'id',
              label: 'ミッションセットID',
              validators: [
                pathValidator,
                max50Validator,
              ]
            },
            {
              name: 'name',
              label: 'ミッションセット名(表示用)',
              validators: [
                max50Validator,
              ]
            },
            {
              name: 'kgTaskSetId',
              label: 'タスクセットID(keigan用)',
              required: false,
              hidden(values) { return !isDriveUnitKeigan(values) },
              validators: [
                {
                  re: /^[0-9]*$/,
                  helperText: '数字のみで入力してください',
                },
                max50Validator,
              ],
            },
            {
              name: 'nsMissionName',
              label: '実行するミッション名(走行系送信用)',
              validators: [
                max50Validator,
              ],
              hidden(values) { return isDriveUnitKeigan(values) }
            },
            {
              name: 'nsStartPoseName',
              label: '開始位置名',
              validators: [
                max50Validator,
              ],
              hidden(values) { return isDriveUnitKeigan(values) }
            },
            {
              name: 'nsMapName',
              label: '地図名',
              validators: [
                max50Validator,
              ],
              hidden(values) { return isDriveUnitKeigan(values) }
            },
            {
              type: 'select',
              name: 'floorId',
              label: 'フロア名',
              items: floors,
              itemGetter(item) {
                return {
                  name: item.name,
                  value: item.id,
                }
              },
              filter(values, items) {
                return items.filter((i) => i.tenantId === values['tenantId'])
              },
            },
            {
              name: 'playRule',
              label: '音声再生ルール(JSON)',
              multiline: true,
              maxRows: 10,
            },
            {
              name: 'rhMapId',
              required: false,
              label: 'ROBO-HI用地図ID',
              validators: [
                max100Valicator,
              ]
            },
            {
              name: 'rhFloorCode',
              required: false,
              label: 'ROBO-HI用フロアコード',
              validators: [
                max50Validator,
              ]
            },
            {
              name: 'remarks',
              required: false,
              label: '備考',
              multiline: true,
              validators: [
                max255Validator,
              ]
            },
          ],
        }}
        onSubmit={async (values) => {
          const mission = {
            id: values['id'],
            nsMissionName: values['nsMissionName'],
            name: values['name'],
            nsStartPoseName: values['nsStartPoseName'],
            nsMapName: values['nsMapName'],
            kgTaskSetId: values['kgTaskSetId'],
            floorId: values['floorId'],
            rhMapId: values['rhMapId'],
            rhFloorCode: values['rhFloorCode'],
            playRule: values['playRule'],
            remarks: values['remarks'],
          } as CreateMissionRequest
          dispatch(
            createMissionAsync([
              mission,
              values['robotId'] as string,
              values['tenantId'] as string,
            ]),
          )
          return true
        }}
        onDelete={async ([keys]) => {
          const [id, tenantId, robotId] = String(keys).split('&')
          if (id) {
            const mission = missions.find(
              (m) =>
                m.tenantId === tenantId && m.robotId === robotId && m.id === id,
            )
            const robot = robots.find((r) => r.id === robotId)
            const tenant = tenants.find((t) => t.value === tenantId)
            if (
              mission &&
              (await confirm(
                'ミッションセット削除',
                `${tenant?.name ?? tenantId} / ${robot?.name ?? robotId} / ${
                  mission.name
                }を削除してもよろしいですか?`,
              ))
            ) {
              dispatch(deleteMissionAsync([id, robotId, tenantId]))
            }
          }
        }}
        edit={{
          title: 'ミッションセット編集',
          disables: ['tenantId', 'robotId', 'id'],
          onEdit: async (values, prev) => {
            const mission = {
              id: values['id'],
              nsMissionName: values['nsMissionName'],
              name: values['name'],
              nsStartPoseName: values['nsStartPoseName'],
              nsMapName: values['nsMapName'],
              kgTaskSetId: values['kgTaskSetId'],
              floorId: values['floorId'],
              rhMapId: values['rhMapId'],
              rhFloorCode: values['rhFloorCode'],
              playRule: JSON.stringify(JSON.parse(`${values['playRule']}`)), // 整形したJSONを戻す
              remarks: values['remarks'],
              updatedAt: prev.updatedAt,
            } as CreateMissionRequest
            dispatch(
              updateMissionAsync([
                mission,
                values['robotId'] as string,
                values['tenantId'] as string,
              ]),
            )
            return true
          },
          editRowConverter(row) {
            // JSON整形状態で表示
            return { ...row, playRule: JSON.stringify(JSON.parse(row['playRule']), null, 2) }
          },
        }}
        getRowId={(row) => {
          return row.id + '&' + row.tenantId + '&' + row.robotId
        }}
        rows={missions}
        columns={cols}
        pageSize={10}
      />
    </>
  )
}

export default MissionPage
